// Define Action Types
export const FETCH_EXTENSIONS_REQUESTED = 'FETCH_EXTENSIONS_REQUESTED';
export const FETCH_EXTENSIONS_COMPLETED = 'FETCH_EXTENSIONS_COMPLETED';
export const FETCH_EXTENSIONS_ERROR = 'FETCH_EXTENSIONS_ERROR';

export const MAP_SERVICES_TO_EXTENSIONS_REQUESTED = 'MAP_SERVICES_TO_EXTENSIONS_REQUESTED';
export const MAP_SERVICES_TO_EXTENSIONS_COMPLETED = 'MAP_SERVICES_TO_EXTENSIONS_COMPLETED';
export const MAP_SERVICES_TO_EXTENSIONS_ERROR = 'MAP_SERVICES_TO_EXTENSIONS_ERROR';

export const getExtensionsAsync = () => ({
  type: FETCH_EXTENSIONS_REQUESTED,
});
