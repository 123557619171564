// Define Action Types
export const USER_AUTHORIZE_REQUESTED = 'USER_AUTHORIZE_REQUESTED';
export const USER_AUTHORIZE_COMPLETED = 'USER_AUTHORIZE_COMPLETED';
export const USER_AUTHORIZE_ERROR = 'USER_AUTHORIZE_ERROR';

export const USER_UNAUTHORIZE_REQUESTED = 'USER_UNAUTHORIZE_REQUESTED';
export const USER_UNAUTHORIZE_COMPLETED = 'USER_UNAUTHORIZE_COMPLETED';
export const USER_UNAUTHORIZE_ERROR = 'USER_UNAUTHORIZE_ERROR';

export const USER_ACCEPT_DISCLAIMER_REQUESTED = 'USER_ACCEPT_DISCLAIMER_REQUESTED';
export const USER_ACCEPT_DISCLAIMER_COMPLETED = 'USER_ACCEPT_DISCLAIMER_COMPLETED';
export const USER_ACCEPT_DISCLAIMER_ERROR = 'USER_ACCEPT_DISCLAIMER_ERROR';

export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_COMPLETED = 'GET_USERS_COMPLETED';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_CURRENT_USER_REQUESTED = 'GET_CURRENT_USER_REQUESTED';
export const GET_CURRENT_USER_COMPLETED = 'GET_CURRENT_USER_COMPLETED';
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR';

export const UPDATE_USER_LOCALE_REQUESTED = 'UPDATE_USER_LOCALE_REQUESTED';
export const UPDATE_USER_LOCALE_COMPLETED = 'UPDATE_USER_LOCALE_COMPLETED';
export const UPDATE_USER_LOCALE_ERROR = 'UPDATE_USER_LOCALE_ERROR';

export const ADD_USER_TO_USERS_MAP_REQUESTED = 'ADD_USER_TO_USERS_MAP_REQUESTED';
export const ADD_USER_TO_USERS_MAP_COMPLETED = 'ADD_USER_TO_USERS_MAP_COMPLETED';
export const ADD_USER_TO_USERS_MAP_ERROR = 'ADD_USER_TO_USERS_MAP_ERROR';

export const userAuthorize = () => ({
  type: USER_AUTHORIZE_REQUESTED,
});

export const userUnauthorize = () => ({
  type: USER_UNAUTHORIZE_REQUESTED,
});

export const userAcceptDisclaimer = () => ({
  type: USER_ACCEPT_DISCLAIMER_REQUESTED,
});

export const getUsersAsync = (teamIds = []) => ({
  type: GET_USERS_REQUESTED,
  teamIds,
});

export const getCurrentUserAsync = () => ({
  type: GET_CURRENT_USER_REQUESTED,
});

export const updateUserLocale = (locale) => ({
  type: UPDATE_USER_LOCALE_REQUESTED,
  locale,
});

export const addUserToUsersMap = (user) => ({
  type: ADD_USER_TO_USERS_MAP_REQUESTED,
  user,
});
