/* eslint-disable max-len */
// Define Action Types
export const TOGGLE_SETTINGS_REQUESTED = 'TOGGLE_SETTINGS_REQUESTED';
export const TOGGLE_SETTINGS_COMPLETED = 'TOGGLE_SETTINGS_COMPLETED';

export const TOGGLE_LOAD_SAVE_PRESETS_REQUESTED = 'TOGGLE_LOAD_SAVE_PRESETS_REQUESTED';
export const TOGGLE_LOAD_SAVE_PRESETS_COMPLETED = 'TOGGLE_LOAD_SAVE_PRESETS_COMPLETED';

export const TOGGLE_COLUMNS_REQUESTED = 'TOGGLE_COLUMNS_REQUESTED';
export const TOGGLE_COLUMNS_COMPLETED = 'TOGGLE_COLUMNS_COMPLETED';

export const SET_DEFAULT_SINCE_DATE_TENOR_REQUESTED = 'SET_DEFAULT_SINCE_DATE_TENOR_REQUESTED';
export const SET_DEFAULT_SINCE_DATE_TENOR_COMPLETED = 'SET_DEFAULT_SINCE_DATE_TENOR_COMPLETED';

export const SET_SEARCH_ALL_CUSTOM_DETAILS_REQUESTED = 'SET_SEARCH_ALL_CUSTOM_DETAILS_REQUESTED';
export const SET_SEARCH_ALL_CUSTOM_DETAILS_COMPLETED = 'SET_SEARCH_ALL_CUSTOM_DETAILS_COMPLETED';

export const SET_FUZZY_SEARCH_REQUESTED = 'SET_FUZZY_SEARCH_REQUESTED';
export const SET_FUZZY_SEARCH_COMPLETED = 'SET_FUZZY_SEARCH_COMPLETED';

export const SET_RESPONDERS_IN_EP_FILTER_REQUESTED = 'SET_RESPONDERS_IN_EP_FILTER_REQUESTED';
export const SET_RESPONDERS_IN_EP_FILTER_COMPLETED = 'SET_RESPONDERS_IN_EP_FILTER_COMPLETED';

export const SET_ALERT_CUSTOM_DETAIL_COLUMNS_REQUESTED = 'SET_ALERT_CUSTOM_DETAIL_COLUMNS_REQUESTED';
export const SET_ALERT_CUSTOM_DETAIL_COLUMNS_COMPLETED = 'SET_ALERT_CUSTOM_DETAIL_COLUMNS_COMPLETED';

export const SET_COMPUTED_COLUMNS_REQUESTED = 'SET_COMPUTED_COLUMNS_REQUESTED';
export const SET_COMPUTED_COLUMNS_COMPLETED = 'SET_COMPUTED_COLUMNS_COMPLETED';

export const SET_SHOW_INCIDENT_ALERTS_MODAL_FOR_INCIDENT_ID_REQUESTED = 'SET_SHOW_INCIDENT_ALERTS_MODAL_FOR_INCIDENT_ID_REQUESTED';
export const SET_SHOW_INCIDENT_ALERTS_MODAL_FOR_INCIDENT_ID_COMPLETED = 'SET_SHOW_INCIDENT_ALERTS_MODAL_FOR_INCIDENT_ID_COMPLETED';

export const SET_MAX_RATE_LIMIT_REQUESTED = 'SET_MAX_RATE_LIMIT_REQUESTED';
export const SET_MAX_RATE_LIMIT_COMPLETED = 'SET_MAX_RATE_LIMIT_COMPLETED';

export const SET_AUTO_ACCEPT_INCIDENTS_QUERY_REQUESTED = 'SET_AUTO_ACCEPT_INCIDENTS_QUERY_REQUESTED';
export const SET_AUTO_ACCEPT_INCIDENTS_QUERY_COMPLETED = 'SET_AUTO_ACCEPT_INCIDENTS_QUERY_COMPLETED';

export const SET_AUTO_REFRESH_INTERVAL_REQUESTED = 'SET_AUTO_REFRESH_INTERVAL_REQUESTED';
export const SET_AUTO_REFRESH_INTERVAL_COMPLETED = 'SET_AUTO_REFRESH_INTERVAL_COMPLETED';

export const CLEAR_LOCAL_CACHE_REQUESTED = 'CLEAR_LOCAL_CACHE_REQUESTED';
export const CLEAR_LOCAL_CACHE_COMPLETED = 'CLEAR_LOCAL_CACHE_COMPLETED';

export const SET_DARK_MODE_REQUESTED = 'SET_DARK_MODE_REQUESTED';
export const SET_DARK_MODE_COMPLETED = 'SET_DARK_MODE_COMPLETED';

export const SET_RELATIVE_DATES_REQUESTED = 'SET_RELATIVE_DATES_REQUESTED';
export const SET_RELATIVE_DATES_COMPLETED = 'SET_RELATIVE_DATES_COMPLETED';

// Define Actions
export const toggleSettingsModal = () => ({
  type: TOGGLE_SETTINGS_REQUESTED,
});

export const toggleLoadSavePresetsModal = () => ({
  type: TOGGLE_LOAD_SAVE_PRESETS_REQUESTED,
});

export const toggleColumnsModal = () => ({
  type: TOGGLE_COLUMNS_REQUESTED,
});

export const setDefaultSinceDateTenor = (defaultSinceDateTenor) => ({
  type: SET_DEFAULT_SINCE_DATE_TENOR_REQUESTED,
  defaultSinceDateTenor,
});

export const setSearchAllCustomDetails = (searchAllCustomDetails) => ({
  type: SET_SEARCH_ALL_CUSTOM_DETAILS_REQUESTED,
  searchAllCustomDetails,
});

export const setFuzzySearch = (fuzzySearch) => ({
  type: SET_FUZZY_SEARCH_REQUESTED,
  fuzzySearch,
});

export const setRespondersInEpFilter = (respondersInEpFilter) => ({
  type: SET_RESPONDERS_IN_EP_FILTER_REQUESTED,
  respondersInEpFilter,
});

export const setAlertCustomDetailColumns = (alertCustomDetailFields) => ({
  type: SET_ALERT_CUSTOM_DETAIL_COLUMNS_REQUESTED,
  alertCustomDetailFields,
});

export const setComputedColumns = (computedFields) => ({
  type: SET_COMPUTED_COLUMNS_REQUESTED,
  computedFields,
});

export const setShowIncidentAlertsModalForIncidentId = (incidentId) => ({
  type: SET_SHOW_INCIDENT_ALERTS_MODAL_FOR_INCIDENT_ID_REQUESTED,
  incidentId,
});

export const setMaxRateLimit = (maxRateLimit) => ({
  type: SET_MAX_RATE_LIMIT_REQUESTED,
  maxRateLimit,
});

export const setAutoAcceptIncidentsQuery = (autoAcceptIncidentsQuery) => ({
  type: SET_AUTO_ACCEPT_INCIDENTS_QUERY_REQUESTED,
  autoAcceptIncidentsQuery,
});

export const setAutoRefreshInterval = (autoRefreshInterval) => ({
  type: SET_AUTO_REFRESH_INTERVAL_REQUESTED,
  autoRefreshInterval,
});

export const clearLocalCache = () => ({
  type: CLEAR_LOCAL_CACHE_REQUESTED,
});

export const setDarkMode = (darkMode) => ({
  type: SET_DARK_MODE_REQUESTED,
  darkMode,
});

export const setRelativeDates = (relativeDates) => ({
  type: SET_RELATIVE_DATES_REQUESTED,
  relativeDates,
});
