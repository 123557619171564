/* eslint-disable max-len */
// Define Action Types
export const FETCH_INCIDENTS_REQUESTED = 'FETCH_INCIDENTS_REQUESTED';
export const FETCH_INCIDENTS_COMPLETED = 'FETCH_INCIDENTS_COMPLETED';
export const FETCH_INCIDENTS_ERROR = 'FETCH_INCIDENTS_ERROR';

export const FETCH_ALERTS_FOR_INCIDENTS_REQUESTED = 'FETCH_ALERTS_FOR_INCIDENTS_REQUESTED';
export const FETCH_ALERTS_FOR_INCIDENTS_COMPLETED = 'FETCH_ALERTS_FOR_INCIDENTS_COMPLETED';
export const FETCH_ALERTS_FOR_INCIDENTS_ERROR = 'FETCH_ALERTS_FOR_INCIDENTS_ERROR';

export const FETCH_NOTES_FOR_INCIDENTS_REQUESTED = 'FETCH_NOTES_FOR_INCIDENTS_REQUESTED';
export const FETCH_NOTES_FOR_INCIDENTS_COMPLETED = 'FETCH_NOTES_FOR_INCIDENTS_COMPLETED';
export const FETCH_NOTES_FOR_INCIDENTS_ERROR = 'FETCH_NOTES_FOR_INCIDENTS_ERROR';

export const PROCESS_LOG_ENTRIES = 'PROCESS_LOG_ENTRIES';
export const PROCESS_LOG_ENTRIES_COMPLETED = 'PROCESS_LOG_ENTRIES_COMPLETED';
export const PROCESS_LOG_ENTRIES_ERROR = 'PROCESS_LOG_ENTRIES_ERROR';

export const UPDATE_INCIDENTS = 'UPDATE_INCIDENTS';
export const UPDATE_INCIDENTS_COMPLETED = 'UPDATE_INCIDENTS_COMPLETED';
export const UPDATE_INCIDENTS_ERROR = 'UPDATE_INCIDENTS_ERROR';

export const UPDATE_INCIDENT_ALERTS = 'UPDATE_INCIDENT_ALERTS';
export const UPDATE_INCIDENT_ALERTS_COMPLETED = 'UPDATE_INCIDENT_ALERTS_COMPLETED';
export const UPDATE_INCIDENT_ALERTS_ERROR = 'UPDATE_INCIDENT_ALERTS_ERROR';

export const UPDATE_INCIDENT_NOTES = 'UPDATE_INCIDENT_NOTES';
export const UPDATE_INCIDENT_NOTES_COMPLETED = 'UPDATE_INCIDENT_NOTES_COMPLETED';
export const UPDATE_INCIDENT_NOTES_ERROR = 'UPDATE_INCIDENT_NOTES_ERROR';

export const FILTER_INCIDENTS_LIST = 'FILTER_INCIDENTS_LIST';
export const FILTER_INCIDENTS_LIST_COMPLETED = 'FILTER_INCIDENTS_LIST_COMPLETED';
export const FILTER_INCIDENTS_LIST_ERROR = 'FILTER_INCIDENTS_LIST_ERROR';

// Define Actions
export const getIncidentsAsync = () => ({
  type: FETCH_INCIDENTS_REQUESTED,
});

export const getAlertsForIncidentsAsync = (incidentIds, isRefetch = false) => ({
  type: FETCH_ALERTS_FOR_INCIDENTS_REQUESTED,
  incidentIds,
  isRefetch,
});

export const getNotesForIncidentsAsync = (incidentIds) => ({
  type: FETCH_NOTES_FOR_INCIDENTS_REQUESTED,
  incidentIds,
});

export const updateIncidents = (updatedIncidents = []) => ({
  type: UPDATE_INCIDENTS,
  updatedIncidents,
});

export const updateIncidentAlerts = (incidentId, alerts = []) => ({
  type: UPDATE_INCIDENT_ALERTS,
  incidentId,
  alerts,
});

export const updateIncidentNotes = (incidentId, notes = []) => ({
  type: UPDATE_INCIDENT_NOTES,
  incidentId,
  notes,
});

export const filterIncidents = () => ({
  type: FILTER_INCIDENTS_LIST,
});
